.contact {
  &-box {
    background: white;
    box-shadow:rem(0 59px 101px cl(black,.32));
    padding: rem(50px 80px);
    @include media-breakpoint-down(xs) {
      padding: 2rem 1rem 1rem;
    }
    iframe {
      display: block;
      width:100%;
      height: rem(700px);
      border:0;
      @include media-breakpoint-down(xs) {
        height: 65rem;
      }
    }
    h2 {
      font-size: rem(40px);
      line-height: 1.2;
      font-weight: normal;
      margin: 0;
      text-transform: uppercase;
      text-align: center;
    }
  }
}