.footer {
  background: cl(footer);
  padding: rem(40px 0 25px);
  color:cl(white);
  font-size: rem(14px);
  line-height: 1.2;
  font-family: $Montserrat;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(lg) {
    padding: rem(40px 65px 25px);
  }
  a {
    color:cl(white);
    &:hover {
      text-decoration: underline;
    }
  }
  h5 {
    font-family: $font;
    font-size: rem(24px);
    margin: rem(0 0 25px);
    @include media-breakpoint-down(xs) {
      margin: rem(0 0 20px);
      text-align: center;
    }
  }
  &-logo {
    display: block;
    max-width:rem(350px);
    margin: rem(10px 0);
    @include media-breakpoint-down(xs) {
      margin: rem(0 0 30px);
    }
  }
  .container {
    position: relative;
    z-index: 2;
  }
  &--padding {
    padding-top: rem(185px);
  }
  &-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:rem(16px);
    @include media-breakpoint-down(xs) {
      justify-content: center;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include circle(42px);
      color:cl(white);
      border:rem(1px solid cl(white));
      &:hover,
      &:focus {
        text-decoration: none;
        background: cl(white);
        color:cl(primary);
        border-color:cl(white);
        text-decoration: none;
      }
      i {
        font-size: rem(18px);
      }
    }
  }
  &-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    @include media-breakpoint-down(xs) {
      justify-content: center;
      margin-bottom: rem(30px);
    }
  }
  &-copy {
    display: block;
    font-size: rem(14px);
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }
  &-form {
    position: relative;
    @include media-breakpoint-down(xs) {
      margin-bottom: rem(45px);
    }
    .txt {
      display: block;
      width:100%;
      border:1px solid cl(white);
      height: rem(50px);
      border-radius: rem(25px);
      padding: rem(0 110px 0 20px);
      color:cl(white);
      font-size: rem(19px);
      font-family: $font;
      background: none;
      @include placeholder(cl(white));
    }
    .btn {
      position: absolute;
      right:rem(5px);
      top:rem(5px);
      line-height: rem(38px);
      width:rem(100px);
      padding: 0;
      font-size: rem(14px);
      &:hover,
      &:focus{
        border-color:cl(white);
        background: cl(footer);
      }
    }
  }
  &-decor {
    position: absolute;
    opacity: .05;
    width:rem(452px);
    height: auto;
    @include media-breakpoint-down(xs) {
      width:11rem;
    }
    &--1 {
      left:rem(-16px);
      bottom: rem(16px);
    }
    &--2 {
      right:rem(-16px);
      top: rem(16px);
    }
  }
}

