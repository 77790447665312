.numbers {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  color:cl(white);
  min-height: rem(700px);
  padding: rem(80px 16px 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-bottom:rem(4px solid cl(white));
  @include media-breakpoint-down(lg) {
    padding: 1rem 1rem 5rem;
    min-height: 38rem;
  }
  @include media-breakpoint-down(xs) {
    min-height: auto;
    padding: 2rem 1rem 3rem;
  }
  h3 {
    color:cl(white);
    text-transform: uppercase;
    font-size: rem(60px);
    font-weight: normal;
    margin: 0;
    width:100%;
    text-align: center;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top:rem(47px);
      left:0;
      text-align: center;
    }
    @include media-breakpoint-down(lg) {
      // margin: 0 0 7.5rem;
    }
    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
      font-size: rem(40px);
    }
    span {
      display: block;
      font-size: rem(30px);
      text-transform: none;
      @include media-breakpoint-down(xs) {
        font-size: rem(20px);
      }
    }
  }
  &__circle {
    width:rem(150px);
    height: rem(150px);
    border-radius: 50%;
    position: relative;
    border:1px solid cl(white);
    box-shadow:rem(0 10px 5px cl(black, .03));
    @include media-breakpoint-up(sm) {
      width:rem(350px);
      height: rem(350px);
      box-shadow:rem(0 17px 101px cl(black, .23));
    }
  }
  &__icon {
    position: absolute;
    left:0;
    width:100%;
    top:0;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width:rem(100px);
      @include media-breakpoint-up(sm) {
        width:rem(190px);
      }
    }
  }
  &__hover {
    position: absolute;
    width:100%;
    text-align: center;
    font-size: rem(20px);
    top:rem(-80px);
    span {
      display: block;
      animation: hover 2s infinite linear alternate;
    }
    img {
      display: block;
      margin: 0 auto;
      width:rem(35px);
      height: auto;
      transform: rotate(180deg);
    }
  }
  &__box {
    // @include media-breakpoint-down(xs) {
    //   position: relative;
    //   width:100%;
    //   display: flex;
    //   align-items: center;
    //   margin-bottom: 1rem;
    //   justify-content: center;
    // }
  }
  &__item {

    color:cl(white);
    z-index: 2;
    // @include media-breakpoint-up(sm) {
      position: absolute;
    // }

    &--students {
      top:0;
      left:rem(-125px);
      @include media-breakpoint-down(xs)  {
        left: -6.5rem;
        top:auto;
        bottom: -1rem;
      }
      strong {
        font-size: rem(50px);
      }
      em {
        width:rem(177px);
        @include media-breakpoint-down(xs) {
          width:8.5rem;
        }
        &:before {
          right:rem(-11px);
        }
      }
    }
    &--founded {
      top:0;
      right:rem(-205px);
      text-align: right;
      @include media-breakpoint-down(xs)  {
        top: -1rem;
        right: -8.5rem;
      }
      strong {
        font-size: rem(50px);
      }
      em {
        width:rem(260px);
        @include media-breakpoint-down(xs) {
          width:9.5rem;
        }
        &:before {
          left:rem(-11px);
        }
      }
    }
    &--grade {
      bottom: rem(80px);
      left:rem(-200px);
      @include media-breakpoint-down(xs) {
        bottom: auto;
        top: -1rem;
        left: -6.5rem;
      }
      strong {
        @include media-breakpoint-up(sm) {
          font-size: rem(80px);
        }
      }
      em {
        width:rem(210px);
        @include media-breakpoint-down(xs) {
          width:7.5rem;
        }
        &:before {
          right:rem(-11px);
        }
      }
    }
    &--schools {
      bottom: rem(80px);
      right:rem(-255px);
      text-align: right;
      @include media-breakpoint-down(xs) {
        bottom: -1rem;
        right: -8rem;
      }
      strong {
        @include media-breakpoint-up(sm) {
          font-size: rem(80px);
        }
      }
      em {
        width:rem(265px);
        @include media-breakpoint-down(xs) {
          width:10rem;
        }
        &:before {
          left:rem(-11px);
        }
      }
    }
    &--states {
      bottom: rem(-125px);
      left:rem(135px);
      @include media-breakpoint-up(sm) {
        text-align: center;
      }
      strong {
        font-size: rem(50px);
      }
      em {
        @include media-breakpoint-up(sm) {
          width:1px;
          height: rem(45px)!important;
          margin: 0 auto!important;
        }
        @include media-breakpoint-down(xs) {
          width:rem(200px);
        }
        &:before {
          @include media-breakpoint-up(sm) {
            left:rem(-11px);
          }
          @include media-breakpoint-down(xs) {
            right:rem(-11px);
          }
        }
      }
    }
    strong {
      display: block;
      line-height: 1;
      font-weight: normal;
      @include media-breakpoint-down(xs) {
        font-size: rem(40px)!important;
      }
    }
    span {
      display: block;
      line-height: 1;
      font-size: rem(24px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        font-size: rem(16px)!important;
      }
    }
    em {
      display: block;
      height: 1px;
      background: cl(white);
      position: relative;
      margin: rem(2px 0 8px);
      &:before {
        content: "";
        position: absolute;
        top:rem(-11px);
        width:rem(22px);
        height: rem(22px);
        border:rem(2px solid cl(white));
        border-radius: 50%;
        background: cl(white);
        transition: all .3s linear;
      }
    }
  }

}