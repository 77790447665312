.community {
  &-item {
    display: flex;
    align-items: flex-start;
    gap:rem(40px);
    margin: rem(45px 0 0);
    border-bottom: 2px solid cl(primary);
    padding: rem(0 0 30px);
    &:first-child {
      border-top: 2px solid cl(primary);
      padding-top: rem(30px);
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      gap:rem(45px);
    }
  }
  &-image {
    width: rem(420px);
    flex-shrink: 0;
    @include media-breakpoint-down(xs) {
      width:100%;
    }
    img {
      max-width:90%!important;
      height:auto;
      margin: 0 auto;
    }
  }
  &-text {
    h3 {

    }

  }
}