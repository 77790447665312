.careers {
  &-branch {
    margin: rem(50px 0 80px);
    display: flex;
    justify-content: center;
    gap:rem(37px);
    flex-wrap: wrap;
    @include media-breakpoint-down(xs) {
      margin: rem(40px 0 80px);
    }
    &-item {
      width:calc((100% - 9.250rem) / var(--itemCount));
      --itemCount: 5;
      height: rem(188px);
      padding: rem(20px);
      background: cl(white);
      box-shadow: rem(0 11px 24px cl(black, .18));
      @include media-breakpoint-down(md) {
        --itemCount: 4;
      }
      @include media-breakpoint-down(xs) {
        width:calc((100% - 2.3125rem) /2);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-menu {
    display: flex;
    justify-content: center;
    gap:rem(23px);
    padding: rem(0 0 85px);
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      gap:rem(10px);
      padding: rem(0 0 40px);
    }
    &-item {
      flex: 1 1 0;
      border:rem(2px solid cl(primary));
      padding: rem(10px);
      background: cl(white);
      box-shadow: rem(0 30px 40px cl(black, .14));
      @include media-breakpoint-down(xs) {
        box-shadow: rem(0 10px 25px cl(black, .14));
        padding: rem(5px);
      }
    }
    &-inner {
       background: cl(secondary);
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       gap:rem(20px);
       height: 100%;
       padding: rem(30px 25px);
       color:cl(white);
       @include media-breakpoint-down(xs) {
         flex-direction: row;
         justify-content: flex-start;
         padding: rem(15px 25px);
       }
       &:hover {
         text-decoration: none;
         @include media-breakpoint-up(xl) {
           background: cl(light);
         }
       }
     }
    &-icon {
      width:100%;
      height: rem(90px);
      flex-shrink: 0;
      @include media-breakpoint-down(xs) {
        width:rem(40px);
        height:100%;
      }
      img {
        display: block;
        width:100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-ttl {
      color:cl(white);
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      font-family: $font;
      font-size: rem(30px);
      line-height: 1.1;
      margin: 0;
      @include media-breakpoint-down(xs) {
        font-size: rem(20px);
      }
    }
  }
  &-list {
    list-style: none;
    padding: 0!important;
    &-item {
      background: #f1f2f2;
      border:1px solid #f1f2f2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap:rem(10px);
      padding: rem(15px 30px);
      margin: rem(0 0 9px);
      transition: all .3s ease;
      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
      &:hover {
        @include media-breakpoint-up(xl) {
          background: cl(white);
          border-color:cl(primary);
          box-shadow: rem(0 11px 24px cl(black, .18));
        }
      }
    }
    &-name {

    }
  }
  &-search {
    padding-bottom: rem(10px);
    .form {
      &-control {
        border-color:cl(dark);
        border-radius: rem(25px);
      }
    }
  }
}