.podcast {
  &-item {
    display: flex;
    gap:rem(55px);
    align-items: center;
    margin: rem(0 0 70px);
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      gap:rem(30px);
    }
  }
  &-side {
    width: rem(422px);
    position: relative;
    flex-shrink: 0;
    padding: rem(0 0 23px 23px);
    &:before {
      content: "";
      position: absolute;
      left:0;
      bottom: 0;
      width:1px;
      height: 80%;
      background: cl(primary);
    }
    &:after {
      content: "";
      position: absolute;
      left:0;
      bottom: 0;
      width: 80%;
      height: 1px;
      background: cl(primary);
    }
    @include media-breakpoint-down(xs) {
      width:24rem;
      padding: rem(0 0 16px 16px);
    }
  }
  &-image {
    height: rem(400px);
    display: block;
    @include media-breakpoint-down(xs) {
      height:23rem;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      box-shadow:rem(0 30px 60px cl(black, .32));
    }
  }
  iframe {
    display: block;
    width:100%;
    height: rem(350px);
    border:0;
  }
  &-title {
    overflow: hidden;
    //margin: rem(0 0 30px);
    display: flex;
    justify-content: flex-start;
    h3 {
      position: relative;
      //margin: 0;
      padding-right: rem(23px);
      &:before {
        content: "";
        position: absolute;
        top:rem(24px);
        left:100%;
        width:rem(1000px);
        background: cl(primary);
        height: 1px;
      }
    }
  }
  &-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:rem(24px);
    margin: rem(0 0 30px)
  }
  &-hosts {
    display: flex;
    align-items: center;
    gap:rem(35px);
    margin: rem(0 0 25px);
    &-image {
      width:rem(150px);
      height: rem(150px);
      overflow: hidden;
      border-radius: 50%;
      border:2px solid cl(primary);
      padding: rem(4px);
      background: cl(white);
      box-shadow:rem(0 10px 20px cl(black, .15));
      flex-shrink: 0;
      img {
        display: block;
        width:100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    &-name {
      font-size: rem(28px);
    }
  }
  &-list {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:rem(40px 34px)
  }
  &-box {
    width: calc((100% - 4.250rem) / 3);
    @include media-breakpoint-down(md) {
      width: calc((100% - 2.125rem) / 2);
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
  &-link {
    display: block;
    padding: rem(11px 9px 35px 0);
    border-right:1px solid cl(border);
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background: cl(border);
      right:0;
      top:0;
      height: 1px;
      width:rem(140px);
    }
    &:after {
      content: "";
      position: absolute;
      background: cl(border);
      right:0;
      bottom:0;
      height: 1px;
      width:100%;
      transition: all .3s ease;
    }
    &:hover {
      text-decoration: none;
      @include media-breakpoint-up(xl) {
        .podcast-img {
          box-shadow:rem(0 12px 54px cl(black, .29));
        }
        .podcast-more {
          background: cl(white);
          color:cl(primary);
        }
        &:after {
          width:calc(100% - 3.5rem);
        }
      }
    }
  }
  &-more {
    position: absolute;
    font-size: rem(18px);
    width:rem(42px);
    height: rem(42px);
    border-radius: 50%;
    border:1px solid cl(primary);
    bottom: rem(-21px);
    background: cl(primary);
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: all .3s ease;
  }
  &-img {
    display: block;
    margin: rem(0 0 25px);
    transition: all .3s ease;
    max-height: rem(400px);
    overflow: hidden;
    img {
      width:100%;
      height: auto;
    }
  }
  &-ttl {
    font-family: $font;
    font-size: rem(24px);
    line-height: rem(34px);
    color:cl(primary);
    font-weight: normal;
    margin: 0;
    height: rem(102px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}