.services {
  &-item {
    display: flex;
    gap:rem(55px);
    align-items: center;
    margin: rem(70px 0 0);
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      gap:rem(30px);
    }
  }
  &-side {
    width: rem(422px);
    position: relative;
    flex-shrink: 0;
    padding: rem(35px 0 24px 21px);
    border-bottom: 1px solid cl(primary);
    border-left: 1px solid cl(primary);
    @include media-breakpoint-down(xs) {
      width:100%;
    }
    &:before {
      content: "";
      position: absolute;
      left:0;
      top:0;
      width:rem(48px);
      height: 1px;
      background: cl(primary);
    }
    h3 {
      padding-left: rem(36px);
      margin: -57px 0 10px!important;
    }
  }
  &-image {
    height: rem(320px);
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      box-shadow:rem(0 30px 60px cl(black, .32));
    }
  }
}