.esp {
  &-block {
    display: flex;
    align-items: flex-start;
    padding: rem(0 102px);
    gap:rem(150px);
    @include media-breakpoint-down(lg) {
      flex-direction: column-reverse;
      align-items: center;
      gap:rem(45px);
      padding: rem(0 16px);
    }
  }
  &-text {
    h3 {

    }

  }
  &-side {
    width: rem(642px);
    position: relative;
    flex-shrink: 0;
    padding: rem(35px 20px 25px 0);
    border-bottom: 1px solid cl(primary);
    border-right: 1px solid cl(primary);
    @include media-breakpoint-down(xs) {
      width:100%;
      margin-top: 2rem;
    }
    &:before {
      content: "";
      position: absolute;
      right:0;
      top:0;
      width:rem(170px);
      height: 1px;
      background: cl(primary);
      @include media-breakpoint-down(xs) {
        width: 2.5rem;
      }
    }
    h2 {
      padding-right: rem(160px);
      text-align: right;
      margin: -57px 0 10px!important;
      @include media-breakpoint-down(xs) {
        padding-right: 2rem;
      }
    }
  }
  &-image {
    height: rem(511px);
    @include media-breakpoint-down(xs) {
      height: 20rem;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      box-shadow:rem(0 30px 60px cl(black, .32));
    }
  }
  .static-decor--2 {
    @include media-breakpoint-up(xl) {
      right:30%;
    }
  }
}