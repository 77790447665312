.home {
  &-about {
    background: url(../img/bottom-1.jpg) no-repeat 50% 100%;
    background-size: 100% auto;
    padding-top: rem(65px);
    padding-bottom: 25%;
    position: relative;
    overflow: hidden;
    &-decor {
      position: absolute;
      &--1 {
        width: rem(175px);
        bottom: 28%;
        left: 25%;
        height: auto;
        @include media-breakpoint-down(lg) {
          left: 5%;
        }
      }
      &--2 {
        width: rem(405px);
        top: rem(203px);
        right: 30%;
        height: auto;
        @include media-breakpoint-down(lg) {
          right: 10%;
        }
        @include media-breakpoint-down(xs) {
          opacity: 0.5;
          right: 0;
        }
      }
    }
    &-count {
      display: flex;
      justify-content: space-between;
      padding: rem(0 0 75px);
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(xs) {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
      }
      &-item {
        text-align: center;
        img {
          display: block;
          height: rem(100px);
          width: auto;
          margin: rem(0 auto 15px);
          @include media-breakpoint-down(xs) {
            margin-bottom: rem(5px);
            height: rem(80px);
          }
        }
        strong {
          color: cl(primary);
          font-size: rem(100px);
          line-height: 1;
          font-weight: normal;
          letter-spacing: rem(1px);
          margin: rem(0 0 15px);
          display: block;
          font-family: $fontBold;
          @include media-breakpoint-down(xs) {
            font-size: rem(80px);
            margin-bottom: rem(5px);
          }
        }
        span {
          color: #969696;
          font-size: rem(20px);
          font-weight: 700;
          font-family: $Montserrat;
          display: block;
          @include media-breakpoint-down(xs) {
            font-size: rem(18px);
          }
        }
      }
    }
    &-info {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: rem(33px);
      padding: rem(0 16px);
      position: relative;
      z-index: 2;
      @include media-breakpoint-up(xl) {
        padding: rem(0 102px);
      }
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: center;
      }
      @include media-breakpoint-down(xs) {
        gap: rem(50px);
      }
      &-item {
        position: relative;
        @include media-breakpoint-down(lg) {
          width: rem(550px);
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
        &:nth-child(1) {
          .home-about-info-title {
            justify-content: flex-end;
            flex-direction: row-reverse;
            .line {
              width: calc(100% + 1.313rem);
              margin-left: -1.313rem;
            }
            &:after {
              @include media-breakpoint-up(xl) {
                content: "";
                position: absolute;
                top: rem(22px);
                left: rem(-21px);
                border-left: rem(2px solid cl(primary));
                border-bottom: rem(2px solid cl(primary));
                width: rem(120px);
                height: rem(481px);
              }
            }
            h2 {
              padding-right: 0;
              padding-left: rem(15px);
            }
          }
          .home-about-info-mark {
            bottom: rem(-59px);
            left: rem(107px);
          }
        }
        &:nth-child(2) {
          @include media-breakpoint-up(xl) {
            margin-top: rem(120px);
          }
          .home-about-info-title {
            .line {
              width: calc(100% - 3.125rem);
              margin-right: 3.125rem;
            }
          }
          .home-about-info-mark {
            right: 0;
            top: rem(3px);
          }
        }
        &:nth-child(3) {
          .home-about-info-title {
            .line {
              width: calc(100% + 1.313rem);
              margin-right: -1.313rem;
            }
            &:after {
              @include media-breakpoint-up(xl) {
                content: "";
                position: absolute;
                top: rem(22px);
                right: rem(-21px);
                border-right: rem(2px solid cl(primary));
                border-bottom: rem(2px solid cl(primary));
                width: rem(120px);
                height: rem(481px);
              }
            }
          }
          .home-about-info-mark {
            bottom: rem(-59px);
            right: rem(107px);
          }
        }
        &:hover {
          .home-about-info-mark {
            &:after {
              opacity: 0;
            }
          }
          .home-about-info-mask {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
      &-title {
        position: relative;
        display: flex;
        align-items: center;
        margin: rem(0 0 20px);
        @include media-breakpoint-down(lg) {
          justify-content: center !important;
        }
        @include media-breakpoint-down(xs) {
          margin: rem(0 0 10px);
        }
        .line {
          display: none;
          @include media-breakpoint-up(xl) {
            display: block;
            height: rem(2px);
            background: cl(primary);
            width: 100%;
          }
        }
        h2 {
          font-size: rem(40px);
          line-height: rem(44px);
          font-weight: normal;
          color: cl(primary);
          margin: 0;
          position: relative;
          z-index: 2;
          padding-right: rem(15px);
          flex-shrink: 0;
          @include media-breakpoint-down(lg) {
            padding: 0 !important;
          }
          @include media-breakpoint-down(xs) {
            font-size: rem(36px);
            font-family: $fontBold;
          }
        }
      }
      &-mark {
        display: none;
        @include media-breakpoint-up(xl) {
          display: block;
          position: absolute;
          width: rem(40px);
          height: rem(40px);
          border-radius: 50%;
          background: cl(primary);
          &:before {
            content: "";
            position: absolute;
            top: rem(19px);
            left: rem(11px);
            height: rem(2px);
            background: cl(white);
            width: rem(19px);
          }
          &:after {
            content: "";
            position: absolute;
            top: rem(11px);
            left: rem(19px);
            width: rem(2px);
            background: cl(white);
            height: rem(19px);
            transition: all 0.3s ease;
          }
        }
      }
      &-holder {
        position: relative;
        @include media-breakpoint-up(xl) {
          overflow: hidden;
          box-shadow: rem(0 59px 101px cl(black, 0.32));
        }
      }
      &-image {
        height: rem(400px);
        @include media-breakpoint-down(lg) {
          box-shadow: rem(0 15px 25px cl(black, 0.1));
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-mask {
        background: cl(footer);
        padding: rem(35px 35px 40px 40px);
        font-size: rem(24px);
        line-height: 1.4;
        color: cl(white);
        @include media-breakpoint-up(xl) {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: cl(primary, 0.8);
          padding: rem(35px 35px 40px 40px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          transform: scale(0);
          opacity: 0;
          transition: all 0.3s linear;
        }
        @include media-breakpoint-down(lg) {
          box-shadow: rem(0 15px 25px cl(black, 0.1));
        }
      }
      &-text {
        width: 100%;
        .text-scroll {
          @include media-breakpoint-up(xl) {
            height: rem(240px);
          }
          @include media-breakpoint-down(lg) {
            margin-bottom: rem(20px);
          }
          .mCSB_inside > .mCSB_container {
            margin-right: 6px;
          }
          .mCSB_scrollTools {
            width: 6px;
          }
        }
        p {
          margin: 0;
        }
      }
    }
  }
  &-info {
    padding-top: 3rem;
    padding-bottom: 30%;
    padding-left: rem(16px);
    padding-right: rem(16px);
    background: url(../img/bottom-2.jpg) no-repeat 50% 100%;
    background-size: 100% auto;
    font-size: rem(24px);
    line-height: 1.4;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    @include media-breakpoint-up(xl) {
      padding-top: rem(150px);
      padding-left: rem(102px);
      padding-right: rem(102px);
    }
    @include media-breakpoint-down(md) {
      padding-bottom: 75%;
    }
    @include media-breakpoint-down(xs) {
      gap: rem(40px);
      //padding-bottom: 70%;
      padding-bottom: 27%;
    }
    &--no-bg {
      background: none;
      @include media-breakpoint-up(xl) {
        padding-bottom: 4rem;
      }
      @include media-breakpoint-down(md) {
        padding-bottom: 45%;
      }
      @include media-breakpoint-down(xs) {
        gap: rem(40px);
        padding-bottom: 2rem;
      }
      .home-info-center {
        @include media-breakpoint-down(md) {
          bottom: 4%;
        }
      }
    }
    &-side {
      width: 45%;
      position: relative;
      z-index: 3;
      &--btm {
        @include media-breakpoint-up(lg) {
          margin-top: rem(336px);
        }
      }
      @include media-breakpoint-up(lg) {
        width: 30%;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
    h2 {
      font-size: rem(40px);
      line-height: rem(44px);
      font-weight: normal;
      color: cl(primary);
      margin: rem(0 0 45px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 25px);
      }
    }
    p {
      margin: rem(0 0 25px);
    }
    &-center {
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 2;
      @include media-breakpoint-up(lg) {
        top: rem(150px);
      }
      @include media-breakpoint-down(md) {
        bottom: 26%;
      }
      @include media-breakpoint-down(xs) {
        //bottom:13%;
        position: relative;
      }
      video {
        display: block;
        //margin: rem(36px auto 0);
        //width:rem(515px);
        margin: 1.5% auto 0;
        width: 28%;
        height: auto;
      }
      video[poster] {
        object-fit: cover;
      }
      .back-image {
        margin: 1.5% auto 0;
        width: 28%;
        aspect-ratio: 0.83;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
    &-decor {
      position: absolute;
      z-index: 1;
      @include media-breakpoint-down(md) {
        opacity: 0.5;
      }
      &--1 {
        width: rem(175px);
        bottom: 47%;
        left: 20%;
        height: auto;
        @include media-breakpoint-down(lg) {
          left: 5%;
        }
      }
      &--2 {
        width: rem(405px);
        top: rem(105px);
        right: 1%;
        height: auto;
        @include media-breakpoint-down(lg) {
          right: 10%;
        }
        @include media-breakpoint-down(xs) {
          opacity: 0.5;
          right: 0;
        }
      }
    }
  }
  &-contact {
    position: relative;
    padding: rem(20px 0 55px);
    .btn {
      &--xl {
        margin-bottom: 4rem;
        font-size: rem(26px);
        border-radius: rem(62px);
        line-height: rem(60px);
        border-radius: rem(30px);
        @include media-breakpoint-up(md) {
          padding: rem(35px 45px);
          border-width: rem(2px);
          font-size: rem(45px);
          letter-spacing: rem(5px);
          border-radius: rem(70px);
          min-width: 70%;
          margin-bottom: rem(80px);
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }
  &-awards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: rem(50px);
    padding: rem(102px 185px 0);
    @include media-breakpoint-down(lg) {
      padding: rem(102px 16px 0);
    }
    @include media-breakpoint-down(md) {
      grid-gap: rem(20px);
    }
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, 1fr);
      padding: 3rem 1rem;
    }
    &-item {
      border: 2px solid cl(primary);
      padding: rem(10px);
      background: cl(white);
      box-shadow: rem(0 30px 40px cl(black, 0.14));
      &-inner {
        background: cl(secondary);
        display: flex;
        gap: rem(10px);
        padding: rem(15px);
        height: 100%;
        align-items: center;
        color: cl(white);
        @include media-breakpoint-between(sm, lg) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 100%;
          padding: rem(10px 20px 20px);
        }
        &:hover {
          text-decoration: none;
        }
      }
      &-logo {
        width: rem(200px);
        flex-shrink: 0;
        @include media-breakpoint-up(xl) {
          height: 100%;
        }
        @include media-breakpoint-down(lg) {
          max-width: 100%;
        }
        @include media-breakpoint-down(xs) {
          width: 10rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      h2 {
        color: cl(white);
        font-weight: normal;
        text-transform: uppercase;
        font-size: rem(30px);
        line-height: 1;
        margin: 0;
        @include media-breakpoint-down(xs) {
          font-size: rem(28px);
        }
      }
    }
  }
  &-slogan {
    position: relative;
    overflow: hidden;
    min-height: rem(273px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    @include media-breakpoint-down(xs) {
      padding-top: 3rem;
    }
    h3 {
      font-size: rem(30px);
      line-height: 1.6;
      font-family: $fontBold;
      color: cl(primary);
      margin: 0;
      text-align: center;
      @include media-breakpoint-down(xs) {
        font-size: rem(28px);
      }
    }
    .container {
      position: relative;
      z-index: 2;
    }
    &-marquee {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      span {
        display: inline-block;
        //padding:0 0 0 100%;
        color: white;
        font-size: rem(273px);
        letter-spacing: rem(36px);
        text-transform: uppercase;
        text-shadow: rem(0 8px 40px cl(black, 0.14));
        white-space: nowrap;
        animation: marquee 100s infinite linear;
        @include media-breakpoint-down(xs) {
          font-size: 5rem;
          letter-spacing: rem(10px);
          text-shadow: rem(0 0 20px cl(black, 0.5));
        }
      }
    }
    @-webkit-keyframes marquee {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(-100%, 0);
      }
    }
    @keyframes marquee {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(-100%, 0);
      }
    }
  }
}
