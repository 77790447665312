.scroll {
  position: fixed;
  left:rem(30px);
  bottom: rem(30px);
  width:rem(100px);
  height: rem(100px);
  padding: rem(17px);
  z-index: 201;
  //@include media-breakpoint-down(lg) {
  //  left:1rem;
  //}
  @include media-breakpoint-down(lg) {
    left:0.5rem;
    bottom:0.5rem;
    //bottom: rem(65px);
    width:5rem;
    height: 5rem;
    padding: 1rem;
  }
  &-text {
    width:100%;
    height: auto;
    position: absolute;
    top:0;
    left:0;
  }
  &-circle {
    width:100%;
    height: 100%;
    border-radius: 50%;
    border:2px solid cl(primary);
    background: cl(gray);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    position: relative;
    &.active {
      i {
        transform: rotate(180deg);
      }
      .scroll-circle-link {
        display: block;
      }
    }
    i {
      position: relative;
      transition: all .3s linear;
      z-index: 2;
    }
    &-progress {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height: 100%;
      z-index: 1;
      background: cl(gray);
      &-bar {
        background: cl(primary);
        width:100%;
        height: 0%;
      }
    }
    &-link {
      position: absolute;
      width:100%;
      height: 100%;
      z-index: 3;
      display: none;
    }
  }
}