/* banner start */
.banner {
  position: relative;
  overflow: hidden;
  &--home {
    height: 100vh;
    @include media-breakpoint-down(lg) {
      //height: 60rem;
    }
    @include media-breakpoint-down(sm) {
      height: 31rem;
    }
    @include media-breakpoint-down(xs) {
      height: 100vh;
      //height: 35rem;
    }
  }
  &--picture {
    @include media-breakpoint-up(lg) {
      // background-attachment: fixed;
    }
    &:before{
      content: "";
      @extend .full;
      background: radial-gradient(ellipse at center,rgba(55,73,66,0) 0,rgba(55,73,66,.5) 48%,rgba(55,73,66,.8) 70%,#374942 100%);
    }
  }
  &--static {
    &:before{
      content: "";
      @extend .full;
      background:cl(black,.45);
    }
  }
  &--landing {
    @include media-breakpoint-down(xs) {
      height: calc(100vh - 5rem);
    }
  }
  &--page {
    height: rem(550px);
    background-color: cl(primary);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    @include media-breakpoint-down(xs) {
      font-size: rem(60px);
      height: 25rem;
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: rem(90px);
      line-height: 1;
      font-weight: normal;
      font-family: $font;
      margin: 0;
      @include media-breakpoint-down(sm) {
        font-size: rem(50px);
      }
    }
  }
  &-icon {
    position: absolute;
    height: auto;
    &--1 {
      width:rem(200px);
      top: 15%;
      right: 10%;
      @include media-breakpoint-down(lg) {
        //width:rem(150px);
        right: 3%;
      }
      @include media-breakpoint-down(xs) {
        width:rem(100px);
        top: 20%;
      }
    }
    &--2 {
      width:rem(150px);
      top: 15%;
      right: 24%;
      @include media-breakpoint-down(lg) {
        //width:rem(100px);
        top: 10%;
        right: 28%;
      }
      @include media-breakpoint-down(xs) {
        width:rem(80px);
        top: 18%;
      }
    }
    &--3 {
      width:rem(125px);
      bottom:10%;
      right:5%;
      @include media-breakpoint-down(lg) {
        width:rem(100px);
        bottom:3%;
      }
      @include media-breakpoint-down(xs) {
        width:rem(70px);
        bottom:3%;
        right:3%;
      }
    }
  }
  &-decor {
    position: absolute;
    opacity: .07;
    &--1 {
      width:rem(175px);
      bottom:5%;
      left:15%;
      height: auto;
      @include media-breakpoint-down(lg) {
        left:5%;
      }
      @include media-breakpoint-down(xs) {
        width:5rem;
      }
    }
    &--2 {
      width:rem(415px);
      top: 25%;
      right: 5%;
      height: auto;
      @include media-breakpoint-down(xs) {
        width:10rem;
      }
    }
  }
  &-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before {
      content: '';
      @extend .full;
      background: cl(black, 0.2);
      z-index: 2;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @include media-breakpoint-down(xs) {
        margin-left: 0;
        top: -20px;
        height: calc(100% + 20px);
      }
      @media #{$min-aspect169} {
        //height: 400%;
        //top: -150%;
      }
      @media #{$max-aspect169} {
        //width: 400%;
        //left: -150%;
      }
    }
    video[poster]{
      object-fit: cover;
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .ttl {
      display: block;
      font-size: rem(100px);
      line-height: 1.1;
      margin: 0;
      font-weight: normal;
      text-transform: uppercase;
      @include media-breakpoint-down(sm) {
        font-size: rem(50px);
      }
    }
    .txt {
      display: block;
      font-size: rem(22px);
      line-height: rem(50px);
      margin: rem(0 0 30px);
    }
    p {
      margin: 0;
    }
  }
  &-slogan {
    position: absolute;
    left:0;
    bottom: 0;
    width:100%;
    background: cl(primary, .96);
    padding: rem(25px 0);
    color:cl(white);
    text-align: center;
    font-size: rem(40px);
    line-height: 1.25;
    font-family: $fontBold;
    z-index: 7;
    @include media-breakpoint-down(sm) {
      font-size: rem(24px);
    }
    p {
      margin: 0;
    }
  }
}
/* banner end */

