.info {
  &-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:rem(25px 46px);
    @include media-breakpoint-down(xs) {
      gap:1rem;
    }
    &--three {
      .info-item {
        @include media-breakpoint-up(lg) {
          width:calc((100% - 5.75rem) / 3);
        }
      }
    }
  }
  &-item {
    box-shadow:rem(0 11px 24px cl(black,.18));
    padding: rem(40px);
    text-align: center;
    background: white;
    position: relative;
    width:calc((100% - 8.625rem)/ 4);
    @include media-breakpoint-down(md) {
      width:calc(50% - 2.188rem);
    }
    @include media-breakpoint-down(xs) {
      width:100%;
      padding: rem(20px);
    }
    img {
      display: block;
      height: rem(90px);
      margin: rem(0 auto 25px);
    }
  }
  &-title {
    display: block;
    font-size: rem(34px);
    line-height: 1.4;
    margin: rem(0 0 20px);
    font-family: $font;
  }
  &-text{
    display: block;
    font-size: rem(24px);
    line-height: 1.4;
    margin: 0;
    font-family: $font;
  }
}