.locations {
  margin-bottom: rem(-152px);
  position: relative;
  z-index: 3;
  .container {
    position: relative;
  }
  &-holder {
    border: rem(2px solid cl(primary));
    padding: rem(12px);
    position: relative;
    z-index: 2;
    &:before {
      content: "";
      position: absolute;
      bottom: rem(-2px);
      width: calc(100% + 0.25rem);
      height: rem(152px);
      left: rem(-2px);
      border: rem(2px solid cl(white));
      border-top: 0;
    }
    &--sm {
      &:before {
        display: none;
      }
      .locations-inner {
        height: rem(430px);
        &:before {
          display: none;
        }
        @include media-breakpoint-down(xs) {
          height: auto;
        }
      }
    }
    .btn {
      &--lg {
        @include media-breakpoint-up(lg) {
          font-size: rem(27px);
          line-height: rem(70px);
          padding: rem(0 40px);
          border-radius: rem(40px);
        }
      }
    }
  }
  &-inner {
    box-shadow: rem(0 59px 101px cl(black, 0.32));
    height: rem(550px);
    display: flex;
    gap: rem(75px);
    align-items: center;
    background-color: #1a7560;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: rem(45px);
    position: relative;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: auto;
      gap: rem(20px);
      padding: rem(45px 25px);
    }
    &:before {
      content: "";
      @extend .full;
      background: cl(dark, 0.6);
      @include media-breakpoint-down(xs) {
        background: cl(dark, 0.8);
      }
    }
    img {
      width: rem(225px);
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(xs) {
        width: 9rem;
      }
    }
  }
  &-text {
    color: cl(white);
    font-size: rem(24px);
    line-height: 1.4;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(xl) {
      width: 60%;
    }
    h3 {
      font-size: rem(40px);
      line-height: 1.2;
      font-weight: normal;
      margin: rem(0 0 35px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 25px);
      }
    }
  }
  &-decor {
    position: absolute;
    width: rem(405px);
    bottom: 50%;
    left: rem(-156px);
    height: auto;
    @include media-breakpoint-down(lg) {
      //right:10%;
    }
    @include media-breakpoint-down(xs) {
      opacity: 0.5;
      //right:0;
    }
    &--2 {
      width: rem(175px);
      bottom: rem(195px);
      left: rem(-225px);
    }
  }
}
