.history {
  &-grid {
    width:rem(1112px);
    margin: 0 auto;
    @include media-breakpoint-down(md) {
      width:rem(800px);
    }
    @include media-breakpoint-down(xs) {
      width:100%;
    }
    &:before {
      @include media-breakpoint-up(sm) {
        content: "";
        position: absolute;
        top:0;
        height: 100%;
        width:2px;
        left:50%;
        margin-left: -1px;
        background: cl(primary);
      }
    }
    .history-item {
      width:rem(420px);
      margin: rem(0 68px 44px);
      @include media-breakpoint-down(md) {
        width:rem(264px);
      }
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 20px);
        width:100%;
      }
      &:nth-child(2) {
        @include media-breakpoint-up(sm) {
          margin-top: rem(70px);
        }
      }
      &.right {
        &:before {
          left:auto;
          right:100%;
          border-top: 23px solid transparent;
          border-right: 23px solid cl(white);
          border-bottom: 23px solid transparent;
          border-left: 0;
        }
        .history-marker {
          right:auto;
          left:rem(-94px);
        }
      }
    }
  }
  &-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap:rem(140px);
    position: relative;
    @include media-breakpoint-down(xs) {
      flex-direction: column-reverse;
      gap:0;
    }
    &:before {
      @include media-breakpoint-up(sm) {
        content: "";
        position: absolute;
        top:0;
        height: 100%;
        width:2px;
        left:50%;
        margin-left: -1px;
        background: cl(primary);
      }
    }
  }
  &-side {
    @include media-breakpoint-up(lg) {
      width:rem(420px);
    }
    &:nth-child(2) {
      @include media-breakpoint-up(sm) {
        padding: rem(70px 0 0);
      }
      .history-item {
        &:before {
          left:auto;
          right:100%;
          border-top: 23px solid transparent;
          border-right: 23px solid cl(white);
          border-bottom: 23px solid transparent;
          border-left: 0;
        }
      }
      .history-marker {
        right:auto;
        left:rem(-96px);
      }
    }
  }
  &-item {
    background: white;
    box-shadow:rem(0 11px 24px cl(black,.18));
    padding: rem(35px);
    margin: rem(0 0 27px);
    position: relative;
    line-height: 1.4;
    &:before {
      @include media-breakpoint-up(sm) {
        content: "";
        position: absolute;
        left:100%;
        top:rem(39px);
        width: 0;
        height: 0;
        border-top: 23px solid transparent;
        border-left: 23px solid cl(white);
        border-bottom: 23px solid transparent;
        z-index: 2;
      }
    }
    p {
      margin: rem(0 0 15px)!important;;
    }
    &-logo {
      width:100%;
      //height: rem(250px);
      img {
        width:100%;
        //display: block;
        //width:100%;
        //height: 100%;
        //object-fit: contain;
      }
    }
  }
  &-marker {
    @include media-breakpoint-up(sm) {
      position: absolute;
      top:rem(40px);
      right:rem(-95px);
      width:rem(52px);
      height: rem(52px);
      border-radius:  50%;
      border:2px solid cl(primary);
      padding: rem(8px);
      background: cl(white);
      box-shadow:rem(0 11px 40px cl(black,.29));
    }
    span {
      display: block;
      background: cl(secondary);
      width:100%;
      height: 100%;
      border-radius:  50%;
    }
  }
}