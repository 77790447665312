.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: cl(primary);
	min-height: 100vh;
	background: cl(white);
	font-family: $font;
	line-height: 1.1;
	@include rem-baseline;
	font-size: calc(10.75px + 15 * ((100vw - 1200px) / 2048));
	@media screen and (min-width: 2049px) {
		font-size: calc(20px + 15 * ((100vw - 2049px) / 4096));
	}
	@include media-breakpoint-down(lg) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(xs) {
		font-size: calc(13px + 2 * ((100vw - 320px) / 575.98));
	}
}
img {
	vertical-align: top;
}
a {
	color: cl(primary);
	text-decoration: none;
	&:hover {
		color: cl(primary);
		text-decoration: underline;
	}
}
strong, b {
	font-family: $fontBold;
	font-weight: normal;
}
.page-slogan {
	position: relative;
	margin: rem(-70px 0 -100px);
	overflow: hidden;
	font-size: rem(273px);
	line-height: 1;
	text-transform: uppercase;
	color:white;
	text-shadow:rem(0 8px 40px cl(black,.14));
	letter-spacing: rem(10px);
	width:100%;
	white-space: nowrap;
	text-align: center;
	@include media-breakpoint-down(lg) {
		font-size: rem(180px);
		margin: rem(-60px 0 -50px);
	}
	@include media-breakpoint-down(md) {
		font-size: rem(150px);
	}
	@include media-breakpoint-down(xs) {
		font-size: rem(70px);
		margin: rem(-50px 0 -20px);
		text-shadow:rem(0 0 10px cl(black,.2));
		letter-spacing: rem(5px);
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.container {
	--size: 85.000rem;
	max-width: var(--size);
	&--small {
		--size: 800px;
	}
}
.wrapper {
	padding: rem(0 190px);
}
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: cl(white);
}
.buttons-bottom {
	position: fixed;
	left:0;
	bottom: 0;
	width:100%;
	z-index: 100;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	background-color: cl(secondary);
	@include media-breakpoint-up(md) {
		display: none;
	}
	.btn {
		width:48%;
		padding: 0;
	}
	& + .footer {
		margin-bottom: 5rem;
	}
	& ~ .scroll {
		@include media-breakpoint-down(sm) {
			bottom: 5rem;
		}
	}
}
@include media-breakpoint-down(lg){
	//.wow {
	//	visibility: visible !important;
	//	-webkit-animation: none !important;
	//	-moz-animation: none !important;
	//	-o-animation: none !important;
	//	-ms-animation: none !important;
	//	animation: none !important;
	//}
}
