.static {
  padding: rem(95px 0);
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    padding: rem(50px 0);
  }
  &--image {
    background: url(../img/bottom-1.jpg) no-repeat 50% 100%;
    background-size: 100% auto;
    padding-bottom: 20%;
  }
  &-decor {
    position: absolute;
    &--1 {
      width:rem(175px);
      bottom:5%;
      left:2%;
      height: auto;
      @include media-breakpoint-down(lg) {
        left:5%;
      }
    }
    &--btm {
      bottom:30%;
    }
    &--2 {
      width:rem(405px);
      top:rem(100px);
      right:5%;
      height: auto;
      @include media-breakpoint-down(lg) {
        right:10%;
      }
      @include media-breakpoint-down(xs) {
        opacity: .5;
        right:0;
      }
    }
  }
  &-article {
    font-size: rem(24px);
    line-height: 1.5;
    color:cl(primary);
    position: relative;
    z-index: 3;
    &-image {
      max-width:rem(600px);
      position: relative;
      padding: rem(0 22px 22px 0);
      margin: rem(0 auto 65px);
      @include media-breakpoint-down(xs) {
        padding: rem(0 10px 10px 0);
        margin: rem(0 auto 35px);
        width:80%;
      }
      &:before {
        content: "";
        position: absolute;
        background: cl(border);
        right:0;
        bottom:0;
        height: 80%;
        width:1px;
      }
      &:after {
        content: "";
        position: absolute;
        background: cl(border);
        right:0;
        bottom:0;
        width: 80%;
        height:1px;
      }
      img {
        box-shadow:rem(0 20px 50px cl(black, .25));
        margin: 0!important;
        max-width: 100%!important;
        width: 100%;
        @include media-breakpoint-down(xs) {
          box-shadow:rem(0 20px 50px cl(black, .25));
        }
      }
    }
    &-video {
      max-width:80%;
      margin: rem(0 auto 80px);
      box-shadow:rem(0 59px 101px cl(black, .32));
      @include media-breakpoint-down(xs) {
        box-shadow:rem(0 20px 50px cl(black, .25));
      }
    }
    &-iframe {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      margin: 0 0 2rem;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    p {
      margin: rem(0 0 35px);
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    h2 {
      font-size: rem(40px);
      font-family: $font;
      margin: rem(0 0 35px);
      font-weight: normal;
    }
    h3 {
      font-size: rem(38px);
      font-family: $font;
      margin: rem(0 0 35px);
      font-weight: normal;
    }
    h4 {
      font-size: rem(36px);
      font-family: $font;
      margin: rem(0 0 35px);
      font-weight: normal;
    }
    h5 {
      font-size: rem(34px);
      font-family: $font;
      margin: rem(0 0 35px);
      font-weight: normal;
    }
    h6 {
      font-size: rem(32px);
      font-family: $font;
      margin: rem(0 0 35px);
      font-weight: normal;
    }
    ol, ul {
      margin: rem(0 0 35px);
      padding: rem(0 0 0 20px);
    }
    img {
      display: block;
      max-width:100%;
    }
    video{
      display: block;
      width:100%;
      height: auto;
    }
  }
}