.form {
  &-group {
    margin:rem(0 0 20px);
    position: relative;
    .view-pass {
      position: absolute;
      bottom:rem(14px);
      right:rem(12px);
      color: cl(primary);
      line-height: rem(16px);
      background: none;
      border:0;
      cursor: pointer;
      &:hover{
        color: cl(primary);
      }
      &:focus {
        outline: none;
        color: cl(primary);
      }
      i{
        font-size: 1rem;
      }
      .fa-eye-slash{
        display: block;
      }
      .fa-eye{
        display: none;
      }
      &.active{
        .fa-eye-slash{
          display: none;
        }
        .fa-eye{
          display: block;
        }
      }
    }
  }
  &-label {
    @extend .ttu;
    display: block;
    color:cl(text);
    font-size: rem(12px);
    margin:rem(0 0 5px);
    font-family: $font;
  }
  &-control,
  .custom-select {
    display: block;
    width:100%;
    font-family: $Montserrat;
    height: rem(50px);
    border-radius: 0;
    background: cl(white);
    border:rem(2px solid cl(white));
    padding:rem(0 20px);
    font-size:rem(16px);
    color:#3d3d3d;
    @include placeholder(cl(text));
    &:focus {
      outline: none;
      box-shadow:none;
      background-color: cl(white);
      border-color:cl(secondary);
    }
  }
  .custom-select {
    background-image: url(../img/select-arrow.png);
    background-repeat: no-repeat;
    background-position: right .5rem center;
    background-size: rem(16px auto);
    padding-right: rem(25px);
  }
  textarea.form-control {
    resize: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height:rem(130px);
  }
  &-check {
    padding: 0;
    margin: 0;
    position: relative;
    &-input-checkbox {
      width: rem(22px);
      height: rem(22px);
      position: absolute;
      z-index: 3;
      opacity: 0;
      left:0;
      top:0;
      @extend .inline-b;
      & + .form-check-label {
        font-weight: normal;
        @extend .inline-b;
        padding: rem(0 0 0 34px);
        margin: 0;
        position: relative;
        font-size: rem(15px);
        line-height: rem(22px);
        color:cl(text);
        &:before {
          content: '';
          position: absolute;
          left:0;
          top:0;
          width: rem(22px);
          height: rem(22px);
          border-radius: rem(3px);
          background: cl(white);
          border:rem(1px solid cl(gray));
        }
        &:after {
          content: '\f00c';
          position: absolute;
          top:0;
          left: 0;
          width: rem(22px);
          height: rem(22px);
          line-height: rem(22px);
          text-align: center;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          opacity: 0;
          font-size: rem(10px);
          color: cl(primary);
        }
      }
      &:checked {
        & + .form-check-label {
          &:before {
            //background: cl(primary);
            //border-color: cl(primary);
          }
          &:after {
            opacity: 1;
          }
        }
      }
    }
    &-input-radio {
      width: rem(22px);
      height: rem(22px);
      position: absolute;
      z-index: 3;
      opacity: 0;
      left:0;
      top:0;
      @extend .inline-b;
      & + .form-check-label {
        font-weight: normal;
        @extend .inline-b;
        padding: rem(0 0 0 34px);
        margin: 0;
        position: relative;
        font-size: rem(15px);
        line-height: rem(22px);
        color:cl(text);
        &:before {
          content: '';
          position: absolute;
          left:0;
          top:0;
          width: rem(22px);
          height: rem(22px);
          border:rem(1px solid cl(gray));
          border-radius: 50%;
          background: cl(white);
        }
      }
      &:checked {
        & + .form-check-label {
          &:before {
            border-color: cl(primary);
            border-width:rem(7px);
          }
        }
      }
    }
  }
  .cards-list {
    display: flex;
    align-items: center;
    min-height: rem(44px);
    img {
      margin: rem(0 3px 0 0);
      width:rem(30px);
      height: auto;
    }
  }
}
