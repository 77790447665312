.results {
  &--bottom {
    margin: rem(0 0 -120px);
    .results-info {
      margin: rem(50px 0 0);
    }
  }
  &-info {
    display: flex;
    gap:rem(23px);
    margin: rem(0 0 65px);
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
    &-item {
      width:calc((100% - 2.875rem) / 3);
      border:2px solid cl(primary);
      background: cl(white);
      padding: rem(10px);
      box-shadow:rem(0 30px 40px cl(black,.14));
      position: relative;
      @include media-breakpoint-down(xs) {
        width:100%;
      }
    }
    &-inner {
      background: cl(secondary);
      min-height: rem(256px);
      height: 100%;
      padding: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color:cl(white);
      @include media-breakpoint-down(md) {
        padding: rem(20px);
      }
      p {
        margin: 0!important;
      }
    }
    &-icon {
      position: absolute;
      width:rem(64px);
      height: auto;
      top:rem(-22px);
      right:rem(-10px);
    }
  }
  &-reward {
    overflow: hidden;
    margin: rem(0 0 35px);
    &-img {
      width:rem(158px);
      margin: 0 auto;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top:rem(73px);
        height: 2px;
        background: cl(primary);
        width:rem(1000px);
      }
      &:before {
        right:calc(100% + 2rem);
      }
      &:after {
        left:calc(100% + 2rem);
      }
    }
  }
  &-list {
    margin: rem(0 -17px);
    padding: rem(30px 0 0);
    @include media-breakpoint-down(xs) {
      margin:0;
    }
    &-item {
      margin: rem(0 17px);
      width:calc((100% - 6.375rem) / 3);
      @include media-breakpoint-down(xs) {
        width:100%;
        margin: 0;
      }
    }
    h3 {
      margin: rem(0 0 20px)!important;
    }
    ul {
      list-style: none;
      border-top:1px solid cl(primary);
      border-left:1px solid cl(primary);
      padding: rem(35px 0 0 28px)!important;
      li {
        margin: rem(0 0 25px);
      }
    }
  }
  &-decor {
    position: absolute;
    &--1 {
      width:rem(175px);
      top:25%;
      left:4%;
      height: auto;
      @include media-breakpoint-down(lg) {
        left:5%;
      }
    }
  }
}