$font: 'goudy_old_styleregular', serif;
$fontBold: 'goudy_old_stylebold', serif;
$Montserrat: 'Montserrat', sans-serif;

$min-aspect169 : "(min-aspect-ratio: 16/9)";
$max-aspect169 : "(max-aspect-ratio: 16/9)";

$colors_theme : (
        "primary"   : "primary",
        "secondary" : "secondary",
        "dark" : "dark",
        "footer" : "footer",
        "border" : "border",
        "text" : "text",
        "gray" : "gray",
        "black" : "black",
        "white" : "white"
);

$colors : (
        'primary'  : #374942,
        'secondary'  : #116f59,
        'dark'  : #293631,
        'light'  : #2b957c,
        'footer'  : #203f32,
        'border'  : #32423c,
        'text'  : #333333,
        'gray'  : #b1b4b4,
        'white' : #FFFFFF,
        'black' : #000000,
);

.color {
  @each $status, $color in $colors {
    &--#{$status} {
      color: $color!important;
    }
  }
}

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@function cl($name, $opacity: false) {
  @if $opacity {
    @return rgba(var(--color-#{unquote($name)}--rgb), $opacity);
  } @else {
    @return var(--color-#{unquote($name)});
  }
}

:root{
  @if $colors {
    @if $colors_theme {
      @each $key, $value in $colors_theme {
        --color-#{$key} : var(--color-#{$value});
        --color-#{$key}--rgb : var(--color-#{$value}--rgb);
      }
    }

    @each $key, $value in $colors {
      --color-#{$key} : #{$value};
      --color-#{$key}--rgb : #{HexToRGB($value)};
    }
  }
}