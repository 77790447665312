/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 7, 2022 */



@font-face {
  font-family: 'goudy_old_styleregular';
  src: url('../fonts/goudy_old_style_regular.eot');
  src: url('../fonts/goudy_old_style_regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/goudy_old_style_regular.woff2') format('woff2'),
  url('../fonts/goudy_old_style_regular.woff') format('woff'),
  url('../fonts/goudy_old_style_regular.ttf') format('truetype'),
  url('../fonts/goudy_old_style_regular.svg#goudy_old_styleregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'goudy_old_stylebold';
  src: url('../fonts/goudy_old_style_bold.eot');
  src: url('../fonts/goudy_old_style_bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/goudy_old_style_bold.woff2') format('woff2'),
  url('../fonts/goudy_old_style_bold.woff') format('woff'),
  url('../fonts/goudy_old_style_bold.ttf') format('truetype'),
  url('../fonts/goudy_old_style_bold.svg#goudy_old_styleregular') format('svg');
  font-weight: normal;
  font-style: normal;

}